// extracted by mini-css-extract-plugin
export var nittContainer = "nitt-module--nittContainer--Gt6cV";
export var aboutUsContainer = "nitt-module--aboutUsContainer--Z67ei";
export var header = "nitt-module--header--VuK+b";
export var middle = "nitt-module--middle--9ZZFA";
export var middleLeft = "nitt-module--middleLeft--VpaME";
export var subtitles = "nitt-module--subtitles--W9nH3";
export var bottom = "nitt-module--bottom--go+FE";
export var bottomLeft = "nitt-module--bottomLeft--zzZwl";
export var bottomRight = "nitt-module--bottomRight--UrBnt";
export var spacedLi = "nitt-module--spacedLi--+zdmi";
export var middleRight = "nitt-module--middleRight--+p7k2";